'use client'

import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Text, SelectionGroup, Spacer, Note } from '@vinted/web-ui'

import { EscrowFees } from 'constants/escrow-fees'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { actions } from 'state/conversation/slice'
import { OfferPriceSuggestionOption } from 'state/conversation/types'
import {
  getOfferRequestSessionId,
  getSelectedOfferPriceSuggestionOption,
} from 'state/conversation/selectors'
import { CurrencyAmountModel, OfferRequestPriceSuggestionModel } from 'types/models'
import { clickEvent } from '_libs/common/event-tracker/events'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { getPriceEstimateWithFees } from 'data/api'
import { transformPriceEstimateWithFees } from 'data/transformers/price-estimate-with-fees'

import { OfferFormField, OfferFormModel } from '../types'
import OfferPriceField from './OfferPriceField'

const CUSTOM_OFFER_PRICE_OPTION: OfferPriceSuggestionOption = {
  isCustom: true,
}

type Props = {
  maxDiscount?: string
  transactionId?: number
  minItemPrice: CurrencyAmountModel | undefined
  maxItemPrice: CurrencyAmountModel | undefined
  currentItemPrice: CurrencyAmountModel
  offerPriceSuggestions: Array<OfferRequestPriceSuggestionModel>
  itemId: number
  orderItemIds?: Array<number>
}

const OfferPriceWithSuggetionsField = ({
  maxDiscount,
  minItemPrice,
  maxItemPrice,
  transactionId,
  currentItemPrice,
  offerPriceSuggestions,
  itemId,
  orderItemIds,
}: Props) => {
  const { register, setValue } = useFormContext<OfferFormModel>()

  const dispatch = useDispatch()
  const { track } = useTracking()
  const translate = useTranslate('conversation.hero_message.make_offer.offer_modal')
  const { locale } = useIntl()

  const selectedOfferPriceOption = useSelector(getSelectedOfferPriceSuggestionOption)
  const offerRequestSessionId = useSelector(getOfferRequestSessionId)

  const { fetch: fetchPriceEstimateWithFees, transformedData: priceEstimate } = useFetch(
    getPriceEstimateWithFees,
    transformPriceEstimateWithFees,
  )

  const getItemIds = useCallback(
    () => (orderItemIds ? orderItemIds.map(String) : [String(itemId)]),
    [orderItemIds, itemId],
  )

  useEffect(() => {
    if (!selectedOfferPriceOption?.suggestion?.price) return

    fetchPriceEstimateWithFees({
      item_ids: getItemIds(),
      offer_price: {
        amount: selectedOfferPriceOption?.suggestion?.price.amount,
        currency_code: selectedOfferPriceOption?.suggestion?.price.currencyCode,
      },
      fees: [EscrowFees.BuyerProtection],
    })
  }, [fetchPriceEstimateWithFees, getItemIds, selectedOfferPriceOption?.suggestion?.price])

  const offerPriceOptions = useMemo<Array<OfferPriceSuggestionOption>>(() => {
    const optionsFromSuggestions = offerPriceSuggestions.map(suggestion => ({
      suggestion,
      isCustom: false,
    }))

    return [...optionsFromSuggestions, CUSTOM_OFFER_PRICE_OPTION]
  }, [offerPriceSuggestions])

  useEffect(() => {
    if (!selectedOfferPriceOption) return

    register(OfferFormField.Offer)
    setValue(OfferFormField.Offer, selectedOfferPriceOption.suggestion?.price.amount || '')
  }, [register, setValue, selectedOfferPriceOption])

  function isSelectedOption(option: OfferPriceSuggestionOption) {
    if (selectedOfferPriceOption?.isCustom) return option.isCustom
    if (!option.suggestion || !selectedOfferPriceOption?.suggestion) return false

    return option.suggestion.price.amount === selectedOfferPriceOption?.suggestion.price.amount
  }

  function getFormattedOfferPriceOptionPrice(offerPriceOption: OfferPriceSuggestionOption) {
    if (!offerPriceOption.suggestion) return ''

    return formatCurrencyAmount(offerPriceOption.suggestion.price, locale)
  }

  function handleOfferPriceOptionClick(
    offerPriceSuggestionOption: OfferPriceSuggestionOption,
    optionIndex: number,
  ) {
    return () => {
      const targetDetails = JSON.stringify({
        transaction_id: transactionId,
        offer_request_session_id: offerRequestSessionId,
        button_number: optionIndex + 1,
        price: offerPriceSuggestionOption.suggestion?.price.amount || null,
        current_price: currentItemPrice.amount,
      })

      track(
        clickEvent({
          screen: Screen.BuyerOriginatingOffer,
          target: ClickableElement.SelectSuggestedOfferDiscount,
          targetDetails,
        }),
      )

      dispatch(actions.setSelectedOfferPriceSuggestionOption({ offerPriceSuggestionOption }))
    }
  }

  const getTotalCombinedPriceText = () => {
    if (!priceEstimate) return null

    return (
      <Text
        as="h4"
        testId="total-combined-price"
        type={Text.Type.Caption}
        text={translate('total_combined_price', {
          price: formatCurrencyAmount(
            {
              amount: priceEstimate.totalPrice.amount,
              currencyCode: priceEstimate.totalPrice.currencyCode,
            },
            locale,
          ),
        })}
      />
    )
  }

  const getOptionTitle = (option: OfferPriceSuggestionOption) => {
    return option.isCustom
      ? translate('price_suggestion_selector.custom_price_option.title')
      : getFormattedOfferPriceOptionPrice(option)
  }

  const getOptionBody = (option: OfferPriceSuggestionOption) => {
    return option.isCustom
      ? translate('price_suggestion_selector.custom_price_option.action')
      : option.suggestion?.label
  }

  const getOptionWidth = () => {
    const optionWidth = '33%'
    const gapOffset = '6px'
    const borderOffset = '1px'

    return `calc(${optionWidth} - ${gapOffset} - ${borderOffset})`
  }

  return (
    <>
      <Spacer />
      <div className="u-ui-margin-horizontal-large" data-testid="price-suggestions">
        <SelectionGroup
          direction={SelectionGroup.Direction.Horizontal}
          styling={SelectionGroup.Styling.Tight}
          layout={SelectionGroup.Layout.Default}
        >
          {offerPriceOptions.map((option, index) => (
            <SelectionGroup.SelectionItem
              key={[option.isCustom, option.suggestion?.label].join('-')}
              title={getOptionTitle(option)}
              body={
                <Text
                  text={getOptionBody(option)}
                  theme="primary"
                  type={Text.Type.Caption}
                  as="span"
                />
              }
              isSelected={isSelectedOption(option)}
              showSelectionIndicator={false}
              size={SelectionGroup.SelectionItem.Size.Small}
              onClick={handleOfferPriceOptionClick(option, index)}
              width={getOptionWidth()}
              testId={option.isCustom ? 'custom-offer-price-option' : undefined}
            />
          ))}
        </SelectionGroup>
      </div>
      {selectedOfferPriceOption?.isCustom ? (
        <OfferPriceField
          showTitle={false}
          maxDiscount={maxDiscount}
          minItemPrice={minItemPrice}
          maxItemPrice={maxItemPrice}
          currentItemPrice={currentItemPrice}
          itemId={itemId}
          orderItemIds={orderItemIds}
        />
      ) : (
        <Note text={getTotalCombinedPriceText()} />
      )}
    </>
  )
}

export default OfferPriceWithSuggetionsField

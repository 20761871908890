import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { CheckoutOrderTypeMap, ExtraServiceOrderType } from 'constants/extra-service'
import { initiateSingleCheckout } from 'data/api'

import { navigateToSingleCheckout } from '_libs/utils/checkout'

import useAbTest from 'hooks/useAbTest'
import { AbTestVariant } from 'constants/abtest'

const useNavigateToSingleCheckout = () => {
  const isSingleCheckoutFSEnabled = useFeatureSwitch('single_checkout_escrow_web')

  const isSingleCheckoutABEnabled =
    useAbTest({
      abTestName: 'single_checkout_escrow',
      shouldTrackExpose: isSingleCheckoutFSEnabled,
    })?.variant === AbTestVariant.On

  const shouldRedirectToNew = isSingleCheckoutFSEnabled && isSingleCheckoutABEnabled

  const navigateToNewCheckout = async (transactionId: number) => {
    if (!shouldRedirectToNew) return null

    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.Transaction]

    const response = await initiateSingleCheckout({
      id: transactionId.toString(),
      type: orderType,
    })

    if ('errors' in response) {
      return null
    }

    return navigateToSingleCheckout(response.checkout.id, transactionId, orderType)
  }

  return { shouldRedirectToNew, navigateToNewCheckout }
}

export default useNavigateToSingleCheckout

import { useIntl } from 'react-intl'

import { selectUnit } from '_libs/utils/date'

const useDate = () => {
  const intl = useIntl()

  const formatRelativeTime = (date: Date | string) => {
    const dateNumber = typeof date === 'string' ? Number(new Date(date)) : Number(date)
    const { value, unit } = selectUnit(dateNumber)

    return intl.formatRelativeTime(value, unit)
  }

  return {
    formatRelativeTime,
  }
}

export default useDate

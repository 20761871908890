'use client'

import { useIntl } from 'react-intl'
import { useWatch } from 'react-hook-form'
import { Cell, Button } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { formatCurrency } from '_libs/utils/formatString'

import useExposee from 'hooks/useExposee'
import useAbTest from 'hooks/useAbTest'
import { abTestExposeEvent } from '_libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'

import { OfferFormField } from '../types'

type Props = {
  isOfferPriceDisplayEnabled?: boolean
  currency: string
  isSubmitting: boolean
  isBuyer?: boolean
}

const OfferSubmitButton = ({
  currency,
  isSubmitting,
  isOfferPriceDisplayEnabled = false,
  isBuyer,
}: Props) => {
  const translate = useTranslate('conversation.hero_message.make_offer')
  const { track } = useTracking()
  const exposee = useExposee()
  const offerRequestsWithoutDiscountLimitABTest = useAbTest({
    abTestName: 'offers_without_discount_limit',
  })
  const { locale } = useIntl()

  const offerValue = useWatch({ name: OfferFormField.Offer, disabled: !isOfferPriceDisplayEnabled })

  function renderTitle() {
    if (isOfferPriceDisplayEnabled) {
      return translate('offer_modal.actions.submit_with_price', {
        price: offerValue ? formatCurrency(offerValue, { locale, currency }) : '',
      })
    }

    return translate('offer_modal.actions.submit')
  }

  const handleSubmit = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isBuyer && offerRequestsWithoutDiscountLimitABTest) {
      track(abTestExposeEvent({ ...exposee, ...offerRequestsWithoutDiscountLimitABTest }))
    }
    if (isSubmitting) {
      event.preventDefault()
    }
  }

  return (
    <Cell>
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        type={Button.Type.Submit}
        styling={Button.Styling.Filled}
        testId="offer-submit-button"
        onClick={handleSubmit}
      >
        {renderTitle()}
      </Button>
    </Cell>
  )
}

export default OfferSubmitButton
